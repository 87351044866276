import { ChangeEvent, FC, useEffect, useState } from 'react';
import { uk } from 'date-fns/locale';
import { Calendar } from 'react-date-range';
import InputMask from 'react-input-mask';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { getSizeRem } from '../Theme';
import leftArrow from '@/assets/datePickerAssets/arrow-left.svg';
import rightArrow from '@/assets/datePickerAssets/arrow-right.svg';
import menuDown from '@/assets/datePickerAssets/menu-down.svg';
import { POPPER_PLACEMENT, Placement, PoperContainer, Popper } from '../Popper';
import { Button } from '../Button';
import { useIsOpenControl } from '@/hooks';
import { TextField, TextFieldProps } from '../Fields';
import { Icon } from '../Icon';
import { FlexGroup } from '../Flex';

import { formatDate, getValidDay, validateDay } from './utils';

export interface DatePickerProps {
  isError?: boolean;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  onClear?: () => void;
  maxDate?: Date | null;
  inputProps?: TextFieldProps;
  container?: PoperContainer;
  placement?: Placement;
}

const StyledWrapper = styled.div(
  ({ theme }) => `
border: 1px solid ${theme.palette.primary[50]};
border-radius: ${getSizeRem(theme.sizes.xxs)};
background-color: ${theme.palette.white};
& .rdrCalendarWrapper {
  padding-bottom: ${getSizeRem(theme.sizes.m)};
  padding-inline: ${getSizeRem(10)};
}
& .rdrMonth {
  font-size: ${getSizeRem(theme.fontSizes.s)};
  width: ${getSizeRem(theme.sizes.base * 10)};
  padding-block: 0;
  padding-inline: ${getSizeRem(theme.sizes.xxs)};
  button:not(.rdrDayDisabled) {
    outline: ${getSizeRem(1)} solid transparent;
    outline-offset: ${getSizeRem(-1)};
    transition: outline-color 0.5s ease-out;
    &:hover, &:focus {
        outline-color: ${theme.palette.primary[300]};
    }
  }
  & .rdrWeekDay {
    line-height: 2.5;
  }
}
& .rdrDayNumber, .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  top: ${getSizeRem(2)};
  bottom: ${getSizeRem(2)};
  left: ${getSizeRem(1)};
  right: ${getSizeRem(1)};
}
& .rdrDay {
  line-height: 1.2;
  height: ${getSizeRem(30)};
}
& .rdrMonthPicker, .rdrYearPicker{
  margin: 0;
}
& .rdrMonthAndYearWrapper {
    padding: 0;
    width: ${getSizeRem(theme.sizes.base * 10)};
    height: ${getSizeRem(theme.sizes.xxl)};
  & select {
    padding-block: ${getSizeRem(theme.sizes.s / 2)};
    padding-left: ${getSizeRem(theme.sizes.xs)};
    padding-right: ${getSizeRem(theme.sizes.base)};
    border: ${getSizeRem(1)} solid ${theme.palette.primary[50]};
    background: url(${menuDown});
    background-position: right ${getSizeRem(theme.sizes.xxs / 2)} center;
  }
  & button, select {
    background-size: ${getSizeRem(theme.sizes.m)};
    background-repeat: no-repeat;
    background-color: ${theme.palette.white};
    transition: background-color 0.5s ease-out;
    &:hover, &:focus{
        background-color: ${theme.palette.primary[25]};
    }
  }
  & button {
    margin: 0;
    background-position: center;
  }
  & button:first-of-type {
    background-image: url(${leftArrow});
  }
  & button:last-of-type {
    background-image: url(${rightArrow});
  }
  & .rdrMonthAndYearPickers {
    gap: ${getSizeRem(theme.sizes.xxs / 2)};
    justify-content: space-around;
  }
}
& span, .rdrDayStartOfWeek>span, .rdrDayEndOfWeek>span, .rdrDayStartOfMonth>span, .rdrDayEndOfMonth>span {
  border-radius: ${getSizeRem(theme.sizes.none)};
}
& span.rdrStartEdge {
  border-top-left-radius: ${getSizeRem(theme.sizes.xxs)};
  border-bottom-left-radius: ${getSizeRem(theme.sizes.xxs)};
}
& span.rdrEndEdge {
  border-top-right-radius: ${getSizeRem(theme.sizes.xxs)};
  border-bottom-right-radius: ${getSizeRem(theme.sizes.xxs)};
}
& .rdrCalendarWrapper:first-of-type span.rdrEndEdge, .rdrCalendarWrapper:last-of-type span.rdrStartEdge {
  background-color: ${theme.palette.primary[25]};
}
& .rdrInRange, .rdrDayPassive > .rdrInRange {
  background-color: ${theme.palette.primary[25]};
  display: flex;
  border-radius: ${getSizeRem(theme.sizes.none)};
}
& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, 
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, 
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, 
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span,
.rdrInRange ~ .rdrDayNumber span,  {
  color: ${theme.palette.primary[600]};
}
& .rdrWeekDay, .rdrMonthAndYearPickers select {
  text-transform: capitalize;
  color: ${theme.palette.grey[900]};
}
& button.rdrDayHovered>span {
    border: none;
    ::after {
        display: none;
    }
}
& i {
  display: none;
}
`
);

export const DatePicker: FC<DatePickerProps> = ({
  isError = false,
  placeholder = process.env.REACT_APP_DATE_FORMAT,
  value,
  onChange,
  onClear,
  maxDate = new Date(),
  inputProps,
  container,
  placement = POPPER_PLACEMENT.B_START,
}) => {
  const { t } = useTranslation();
  const [input, setInput] = useState<string | undefined>('');
  const { isOpen, close, open } = useIsOpenControl();

  useEffect(() => {
    setInput(value);
  }, [value]);

  const handleChange = (value: Date) => {
    const dateString = format(value, process.env.REACT_APP_DATE_FORMAT);
    onChange(dateString);
    close();
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === placeholder) onChange('');
    const [yyyy, mm, dd] = value.split('-');
    const day = Number(dd);
    const dayFirstChart = Number(dd?.charAt(0));
    const month = Number(mm);
    const monthFirstChart = Number(mm?.charAt(0));
    const year = Number(yyyy);
    const notValidYear = year > currentYear;
    const notValidMonth = (year === currentYear && month > currentMonth) || month > 12 || monthFirstChart > 1;
    const notValidDay = dayFirstChart > 3 || validateDay({ day, month, year, currentDay, currentMonth, currentYear });

    if (notValidYear) {
      setInput(`${currentYear}-`);
    } else if (notValidMonth) {
      const validMonth = year === currentYear ? formatDate(currentMonth) : formatDate(monthFirstChart);
      setInput(`${yyyy}-${validMonth}-`);
    } else if (notValidDay) {
      const validDay = getValidDay({ dayFirstChart, month, year, currentDay, currentMonth, currentYear });
      const date = `${yyyy}-${mm}-${validDay}`;
      setInput(date);
      if (Date.parse(date)) onChange(date);
    } else {
      setInput(value);
      if (Date.parse(value)) onChange(value);
    }
  };

  const handleClear = () => {
    onChange('');
    setInput('');
    close();
    if (onClear) {
      onClear();
    }
  };

  const handleSetToday = () => {
    const date = format(new Date(), process.env.REACT_APP_DATE_FORMAT);
    onChange(date);
    close();
  };

  return (
    <Popper
      anchor={
        <InputMask mask="9999-99-99" onFocus={open} onChange={handleInput} value={input} maskPlaceholder={placeholder}>
          <TextField
            isError={isError}
            startAdornment={<Icon type="calendar-blank" size="s" className="ml-xs" />}
            placeholder={placeholder}
            px="xxs"
            py="s"
            fontSize="s"
            {...inputProps}
          />
        </InputMask>
      }
      isOpen={isOpen}
      placement={placement}
      close={close}
      container={container}
    >
      <StyledWrapper onMouseDown={(e) => e.stopPropagation()}>
        <Calendar
          date={value ? new Date(value) : new Date()}
          onChange={handleChange}
          maxDate={maxDate === null ? undefined : maxDate}
          locale={uk}
          weekdayDisplayFormat="EEEEEE"
          color="#99AFFF"
        />
        <FlexGroup border="border-top" className="py-s px-l" justify="space-between">
          <Button color="secondary" px="s" py="xs" textSize="s" weight="semibold" onClick={handleClear}>
            {t('datePicker.clear')}
          </Button>
          <Button color="secondary" px="s" py="xs" textSize="s" weight="semibold" onClick={handleSetToday}>
            {t('datePicker.today')}
          </Button>
        </FlexGroup>
      </StyledWrapper>
    </Popper>
  );
};
