import { millisecondsToSeconds } from 'date-fns';
import { Rectangle, Map, Polygon } from 'leaflet';

import { TrackPoint } from '../Ellipse/type';
import { Spacing, SpacingWithExtra } from '@/components/Core';

const MARKER_CONFIG = {
  maxval: 1800, // seconds
  maxOpacity: 0.9,
  minOpacity: 0.7,
  maxFillOpacity: 0.6,
  minFillOpacity: 0.4,
};

export const PULSE_COLOR = '#00FFFF';
export const PULSE_AGE = 110; //in seconds

export const MARKER_SIZES: Record<SpacingWithExtra, [number, number]> = {
  none: [1500, 1],
  xxs: [1500, 1],
  xs: [1500, 1],
  s: [2000, 5],
  m: [2500, 10],
  base: [3000, 15],
  l: [3200, 20],
  xl: [3500, 22],
  xxl: [4500, 25],
  extra: [7000, 30],
};
export const SUPER_MARKER_SIZES: Record<SpacingWithExtra, [number, number]> = {
  none: [1000, 0.5],
  xxs: [1000, 0.5],
  xs: [1000, 0.5],
  s: [1800, 2],
  m: [2200, 7],
  base: [2500, 12],
  l: [2800, 17],
  xl: [3000, 19],
  xxl: [4000, 22],
  extra: [6000, 28],
};
export const SMALL_MARKER_SIZES: Record<SpacingWithExtra, [number, number]> = {
  none: [100, 0.25],
  xxs: [100, 0.25],
  xs: [100, 0.25],
  s: [500, 0.4],
  m: [650, 1],
  base: [800, 3],
  l: [1000, 6],
  xl: [1500, 12],
  xxl: [2500, 18],
  extra: [5000, 24],
};
export const BORDER_SIZE: [number, number] = [1, 3];

export const DEFAULT_SIGMA_MOVING_AVERAGE: Types.SigmaMovingAverage = {
  drone: 30,
  rocket: 10,
  spy_drone: 45,
};

export function getOpacityMarker(time: number, isCanceled: boolean) {
  const age = getAge(time);

  let opacity = linear(age, MARKER_CONFIG.maxval, MARKER_CONFIG.maxOpacity, MARKER_CONFIG.minOpacity);
  let fillOpacity = linear(age, MARKER_CONFIG.maxval, MARKER_CONFIG.maxFillOpacity, MARKER_CONFIG.minFillOpacity);

  if (isCanceled) {
    opacity = opacity / 3;
    fillOpacity = fillOpacity / 3;
  }

  return {
    opacity,
    fillOpacity,
  };
}

export function getAge(time: number) {
  const currentTime = millisecondsToSeconds(new Date().getTime());
  return currentTime - time;
}

export function linear(val: number, maxval: number, from: number, to: number) {
  return from < to ? Math.min(from + (val / maxval) * to, to) : Math.max(from - (val / maxval) * (from - to), to);
}

export function fetchVelocity(metadata: any) {
  return metadata.droneVelocity * 1200 || metadata.rocketVelocity * 1200 || undefined;
}

export function getMarkerColor(p: Types.EventPoint, status?: string | undefined) {
  if (p.type === 'spy_drone') {
    switch (p.user_id) {
      case 195837:
        return '#5d0303'; //hugo
      case 188203:
        return '#e65f07'; //віраж
      case 193746:
      case 193475:
      case 199498:
        return '#8835ca'; //хмара
      case 193292:
      case 194474:
        return '#e707ac'; //рада
      default:
        return '#e65f07';
    }
  }

  if (p.type === 'land') {
    return '#ff0000';
  }

  if (p.type === 'drone') {
    switch (status) {
      case 'decoy_drone':
      case 'herbera':
      case 'parody':
        return '#6b2a01';
    }
  }

  if (p.type === 'drone' && p.additional_data && p.additional_data['superduperDrone'] === 1) {
    return '#ff0000';
  }
  if (p.type === 'drone' && (p.status === 'automoderated' || p.status === 'high_confidence')) {
    return '#5b0000';
  }
  if (p.type === 'drone') {
    return '#ff0000';
  }

  if (p.type == 'rocket' && (p.status === 'automoderated' || p.status === 'high_confidence')) {
    return '#50006f';
  }
  if (p.type == 'rocket') {
    return '#b700ff';
  }

  if (p.type === 'slow_drone' && (p.status === 'automoderated' || p.status === 'high_confidence')) {
    return '#5b0000'; // '#f4a234';
  }
  if (p.type === 'slow_drone') {
    return '#ff0000'; // '#ed820e';
  }
}

export function interpolationSize(num: number, from: [number, number], to: [number, number]): number {
  if (num < from[0]) {
    return to[0];
  } else if (num > from[1]) {
    return to[1];
  } else {
    const t = (num - from[0]) / (from[1] - from[0]);
    return to[0] * Math.pow(to[1] / to[0], t);
  }
}

function toRadians(degree: number): number {
  return degree * (Math.PI / 180);
}

export function haversine([lat1, lon1]: [number, number], [lat2, lon2]: [number, number]): number {
  const R = 6371.0; // Радіус Землі в кілометрах

  // Переведення градусів у радіани

  const lat1_rad = toRadians(lat1);
  const lon1_rad = toRadians(lon1);
  const lat2_rad = toRadians(lat2);
  const lon2_rad = toRadians(lon2);

  // Різниця координат
  const dlat = lat2_rad - lat1_rad;
  const dlon = lon2_rad - lon1_rad;

  // Формула Haversine
  const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1_rad) * Math.cos(lat2_rad) * Math.sin(dlon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Відстань
  const distance = R * c;
  return distance;
}

export function combine<T>(a: T[], b: T[]): [T, T][] {
  return a.map((p, i) => [p, b[i]]);
}

export function getRectangleCoords(center: [number, number], distance: number): [[number, number], [number, number]] {
  const [lat, lng] = center;

  // Earth radius in meters
  const earthRadius = 6378137;

  // Convert distance from meters to degrees
  const distanceInDegrees = (distance / earthRadius) * (180 / Math.PI);

  const deltaLat = distanceInDegrees;
  const deltaLng = distanceInDegrees / Math.cos((lat * Math.PI) / 180);

  const topLeft: [number, number] = [lat + deltaLat, lng - deltaLng];
  const bottomRight: [number, number] = [lat - deltaLat, lng + deltaLng];

  return [topLeft, bottomRight];
}

export function getTriangleCoords(
  center: [number, number],
  distance: number
): [[number, number], [number, number], [number, number]] {
  const [lat, lng] = center;

  // Earth radius in meters
  const earthRadius = 6378137;

  // Convert distance from meters to degrees
  const distanceInDegrees = ((distance * 2) / earthRadius) * (180 / Math.PI);

  // Три кути для вершин трикутника (120° між кожною вершиною)
  const angles = [180, 300, 60].map((angle) => (angle * Math.PI) / 180);

  // Обчислюємо координати вершин трикутника
  const vertices = angles.map((angle) => {
    const x = lat + distanceInDegrees * Math.cos(angle);
    const y = lng + distanceInDegrees * Math.sin(angle);
    return [x, y] as [number, number];
  });

  return vertices as [[number, number], [number, number], [number, number]];
}

export function resizeMarker(marker: Rectangle | null, map: Map, size: [number, number]) {
  if (marker) {
    const markerSize = interpolationSize(map.getZoom(), [map.getMinZoom(), map.getMaxZoom()], size);
    const borderSize = interpolationSize(map.getZoom(), [map.getMinZoom(), map.getMaxZoom()], BORDER_SIZE);
    const { lat, lng } = marker.getCenter();
    marker
      .setStyle({
        weight: borderSize,
      })
      .setBounds(getRectangleCoords([lat, lng], markerSize));
  }
}

export function resizeMarkerTriangle(marker: Polygon | null, map: Map, size: [number, number]) {
  if (marker) {
    const markerSize = interpolationSize(map.getZoom(), [map.getMinZoom(), map.getMaxZoom()], size);
    const borderSize = interpolationSize(map.getZoom(), [map.getMinZoom(), map.getMaxZoom()], BORDER_SIZE);
    const { lat, lng } = marker.getCenter();
    marker
      .setStyle({
        weight: borderSize,
      })
      .setLatLngs(getTriangleCoords([lat, lng], markerSize));
  }
}

export function getTrackPoint(ellipse: Types.Ellipse): TrackPoint {
  const { x, y } = ellipse.data as Types.EllipseData;

  return {
    coords: [y, x],
    id: ellipse.id as number,
    t: Math.round(Number(ellipse.timestamp)),
    type: ellipse.type,
    rcs: ellipse.rcs,
    speed: ellipse.speed,
    altitude: ellipse.altitude,
    user_id: ellipse.user_id,
  };
}

export const getMarkerSize = (sizes: Record<SpacingWithExtra, [number, number]>, value: Spacing, isExtra: boolean) => {
  if (isExtra) {
    return sizes['extra'];
  }
  return sizes[value];
};
