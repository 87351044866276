import { createSlice } from '@reduxjs/toolkit';

import { PublicSettingsKeys, State } from './types';
import { getRoles, getSettings } from './thunks';

const initialState: State = {
  items: [],
  settings: {},
  isLoading: true,
  isLoadingSettings: true,
};
//TODO rename to app;

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    clear: (state) => {
      state.items = [];
      state.isLoading = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.items = action.payload;
        state.isLoading = false;
      });

    builder
      .addCase(getSettings.fulfilled, (state, action) => {
        if (action.payload)
          state.settings = action.payload.reduce(
            (acc, { key, value }) => (key && value ? { ...acc, [key]: value } : acc),
            {} as Record<PublicSettingsKeys, string>
          );
        state.isLoadingSettings = false;
      })
      .addCase(getSettings.rejected, (state) => {
        state.settings = {};
        state.isLoadingSettings = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  getRoles,
  getSettings,
};

export default slice.reducer;
