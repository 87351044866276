import { createSelector } from '@reduxjs/toolkit';

import type { ReduxState } from '../../store';
import { ROLES } from '@/utils/constants';

export const getRoles = (state: ReduxState) => state.roles.items;
export const getIsLoading = (state: ReduxState) => state.roles.isLoading;

export const getSettings = (state: ReduxState) => state.roles.settings;
export const getIsLoadingSetting = (state: ReduxState) => state.roles.isLoadingSettings;

const EXCEPTION = [ROLES.BIRDWATCHER, ROLES.SOUND_APP];

export const getItems = createSelector([getRoles], (roles) =>
  roles.filter(({ name }) => !EXCEPTION.includes(name as ROLES))
);
export const getSoundAppRole = createSelector([getRoles], (roles) =>
  roles.find(({ name }) => name === ROLES.SOUND_APP)
);

const DEVICE_MANAGER = ROLES.DEVICE_MANAGER;
export const getDeviceManagerRole = createSelector([getRoles], (roles) =>
  roles.find(({ name }) => name === DEVICE_MANAGER)
);
