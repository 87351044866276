import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';
import { roleSelectors } from '../roles';

export const getItems = createAppAsyncThunk(
  'devices/getItems',
  async (payload: Paths.AuthDevicesList.Get.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getDevices(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const editById = createAppAsyncThunk('devices/editById', async (payload: Payloads.DeviceEdit, thunkApi) => {
  try {
    await api.editUserById(payload);
    const {
      data: { data },
    } = await api.getUser({ user_id: payload.user_id });
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getById = createAppAsyncThunk(
  'devices/getById',
  async (payload: Payloads.UserPropertiesList, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getUser({ user_id: payload.user_id });
      const {
        data: { data: reportsData },
      } = await api.getDeviceReports(payload);
      const {
        data: { data: heartbeatsData },
      } = await api.getDeviceHeartbeats(payload);
      const {
        data: { data: userAgentsData },
      } = await api.getDeviceUserAgents(payload);
      return {
        deviceDetails: data,
        reportsData,
        heartbeatsData,
        userAgentsData,
      };
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getDeviceManagers = createAppAsyncThunk('devices/getDeviceManagers', async (_, thunkApi) => {
  try {
    const deviceManager = roleSelectors.getDeviceManagerRole(thunkApi.getState());
    const {
      data: {
        data: { users },
      },
    } = await api.getUsers({ roles: deviceManager?.id.toString() });
    return users;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getDeviceReports = createAppAsyncThunk(
  'devices/getDeviceReports',
  async (payload: Payloads.UserPropertiesList, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getDeviceReports(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getDeviceHeartbeats = createAppAsyncThunk(
  'devices/getDeviceHeartbeats',
  async (
    payload: Paths.AuthDevices$UserIdHeartbeats.Get.PathParameters &
      Paths.AuthDevices$UserIdHeartbeats.Get.QueryParameters,
    thunkApi
  ) => {
    try {
      const {
        data: { data },
      } = await api.getDeviceHeartbeats(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getDeviceUserAgents = createAppAsyncThunk(
  'devices/getDeviceUserAgents',
  async (payload: Payloads.UserPropertiesList, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getDeviceUserAgents(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
